import { useState } from 'react';
import { Box, Button, IconButton, Flex, Heading, Text, VStack, Textarea, Tooltip, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  useGetProfileNotesQuery,
  useCreateProfileNoteMutation,
  useUpdateProfileNoteMutation
} from '@services/canaria.services';
import { selectActiveOrgID } from '../../../features/user-settings/userSlice';
import dayjs from 'dayjs';

interface ProfileNotesProps {
  profileId: string;
}

const ProfileNotes: React.FC<ProfileNotesProps> = ({ profileId }) => {
  const toast = useToast();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('No active org ID found');
  }
  const { data: notes = [], refetch } = useGetProfileNotesQuery({
    orgId: activeOrgID,
    profileId
  });
  const [createProfileNote] = useCreateProfileNoteMutation();
  const [updateProfileNote] = useUpdateProfileNoteMutation();

  const [newNote, setNewNote] = useState<string>('');
  const [editNoteId, setEditNoteId] = useState<string | null>(null);
  const [editNote, setEditNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);

  const handleAddNote = async (): Promise<void> => {
    setLoading(true);
    try {
      if (newNote.trim().length > 0) {
        await createProfileNote({
          orgId: activeOrgID,
          profileId,
          content: newNote
        }).unwrap();
        setNewNote('');
        await refetch();
        toast({
          title: 'Note created.',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error creating note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setLoading(false);
      setShowAddNote(false);
    }
  };

  const handleUpdateNote = async (): Promise<void> => {
    try {
      if (editNote.trim().length > 0 && editNoteId != null) {
        await updateProfileNote({
          orgId: activeOrgID,
          profileId,
          noteId: editNoteId,
          content: editNote
        }).unwrap();
        setEditNoteId(null);
        setEditNote('');
        await refetch();
        toast({
          title: 'Note updated.',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error updating note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch" w="100%">
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Heading as="h1" size="md" textAlign="left" mb={5}>
          Notes
        </Heading>
        <Button
          onClick={() => {
            setShowAddNote(!showAddNote);
          }}
          colorScheme="teal"
          borderRadius="full"
        >
          +
        </Button>
      </Flex>
      {showAddNote && (
        <Box>
          <Textarea
            placeholder="Add a new note"
            value={newNote}
            onChange={(e) => {
              setNewNote(e.target.value);
            }}
            mb={2}
          />
          <Button onClick={handleAddNote} colorScheme="teal" isLoading={loading}>
            Add Note
          </Button>
        </Box>
      )}
      {notes?.map((note) => (
        <Box key={note.id} px={3} py={2} shadow="md" borderWidth="1px" w="100%" position="relative">
          {editNoteId === note.id ? (
            <Flex direction="column">
              <Textarea
                value={editNote}
                onChange={(e) => {
                  setEditNote(e.target.value);
                }}
                mb={2}
              />
              <Flex justifyContent="flex-end">
                <IconButton
                  size="sm"
                  icon={<CheckIcon />}
                  aria-label="confirm edit"
                  colorScheme="teal"
                  mr={2}
                  onClick={handleUpdateNote}
                />
                <IconButton
                  size="sm"
                  icon={<CloseIcon />}
                  aria-label="cancel edit"
                  onClick={() => {
                    setEditNoteId(null);
                  }}
                />
              </Flex>
            </Flex>
          ) : (
            <Flex flex="1" justifyContent="space-between" alignItems="center">
              <Text
                onClick={() => {
                  setEditNoteId(note.id);
                  setEditNote(note.content);
                }}
                flex="1"
              >
                {note.content}
              </Text>
              <Flex direction="column" alignItems="flex-end" justifyContent="space-between">
                <Tooltip label={`Updated by ${note.updatedBy}`} placement="top" isDisabled={note.updatedBy === ''}>
                  <Text fontSize="xs" color="gray.500">
                    {dayjs(note.updatedAt).format('YYYY-MM-DD')}
                  </Text>
                </Tooltip>
                <Button
                  onClick={() => {
                    setEditNoteId(note.id);
                    setEditNote(note.content);
                  }}
                  size="sm"
                  colorScheme="teal"
                  my={2}
                >
                  <EditIcon />
                </Button>
                <Text fontSize="xs" color="gray.500">
                  {note.createdBy}
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      ))}
    </VStack>
  );
};

export default ProfileNotes;
