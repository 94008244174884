import { useEffect, type JSX } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoggedIn } from '../features/auth/authSlice';
import AuthAPI from '../features/auth/api';

const LogoutPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      const authApi = new AuthAPI(dispatch);
      authApi.logout();
    }
  }, [isLoggedIn, dispatch]);

  return <Navigate to="/" />;
};

export default LogoutPage;
