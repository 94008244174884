import { useState, useEffect, type JSX } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetAlertByIDQuery, useUpdateAlertMutation } from '@services/canaria.services';
import { selectActiveOrgID } from '../../user-settings/userSlice';
import * as Sentry from '@sentry/browser';
import analytics from '../.././analytics/analytics';
import {
  Container,
  Grid,
  GridItem,
  Divider,
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Textarea,
  Button,
  Tag,
  useToast
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { getTriggerDifferences } from '../../../utils/alerts';
import AlertBox from '../../../pages/v2/ProfileOverview/AlertBox';
import Loading from '../../loading/loading.component';

enum ALERT_STATUS {
  OPEN = 'O',
  CLOSED = 'C'
}

const AlertHeader = ({ alert }): JSX.Element => {
  const title =
    alert.resourcetype === 'WatchlistAlert'
      ? `Alert (ID: ${alert.id}) ${alert.name}`
      : `Alert (ID: ${alert.id}) ${alert.wallet?.name} - (${alert.wallet?.address})`;
  return (
    <Box p={5} bg="white" outline="1px solid black" mb={4}>
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Text fontSize="xl" fontWeight={700}>
            {title}
          </Text>
          {alert.resourcetype === 'WatchlistAlert' && (
            <>
              <Box>Profile: {alert.profile.name}</Box>
              <Box>Sanctions found: {alert.profile_inquiries?.length}</Box>
            </>
          )}
        </Flex>
        <Flex direction="column">
          <Text>Created: {dayjs(alert?.created_at).format('YYYY-MM-DD hh:mm:ss A')}</Text>
          <Text>Modified: {dayjs(alert?.created_at).format('YYYY-MM-DD hh:mm:ss A')}</Text>
          <Box as="span" fontSize="lg">
            Status:
            <Text as="span" fontWeight={800} ml={2}>
              {alert?.status === ALERT_STATUS.CLOSED ? 'Closed' : 'Open'}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

const AlertBody = ({ alert }): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      {alert.resourcetype === 'WatchlistAlert' ? (
        <Flex p={5} justifyContent="center" alignItems="center">
          <AlertBox
            title={`Found ${alert.profile_inquiries?.length} primary party screening${
              alert.profile_inquiries?.length > 1 ? 's' : ''
            }`}
            buttonText="REVIEW SCREENINGS"
            onButtonClick={() => {
              navigate(`/dashboard/profiles/${alert.profile.id}/screenings`);
            }}
          />
        </Flex>
      ) : (
        <>
          <Box p={5} bg="white" outline="1px solid black" mb={4}>
            <Flex mb="5">
              <Text>Address: </Text>
              <Link to={`/dashboard/wallets/${alert.wallet?.id}`}>
                <Text ml={1} cursor="pointer">
                  {alert.wallet?.address}
                </Text>
              </Link>
            </Flex>
            <Grid templateColumns="auto auto" pt={6} columnGap={5} flex="row">
              <GridItem>
                <Text as="i">Risk</Text>
                <Divider borderColor="gray.300" borderBottom="1px" />
                <Grid templateColumns="auto auto">
                  <GridItem borderBottom="1px" borderColor="gray.300">
                    <Text as="i">Risk Level</Text>
                  </GridItem>
                  <GridItem borderBottom="1px" borderColor="gray.300" py={1}>
                    <Flex alignItems="flex-end">
                      <Tag
                        as="span"
                        bg={`risk.${alert?.current_inquiry_result?.result?.risk.toUpperCase()}`}
                        color="white"
                        ml={2}
                      >
                        {alert?.current_inquiry_result?.result?.risk}
                      </Tag>
                      <Text fontSize="sm" ml={2} as="span">
                        ( Previously{' '}
                        <Tag
                          bg={`risk.${alert?.prev_inquiry_result?.result?.risk.toUpperCase()}`}
                          color="white"
                          size="sm"
                        >
                          {alert?.prev_inquiry_result?.result?.risk}
                        </Tag>
                        )
                      </Text>
                    </Flex>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem>
                <Text as="i">Cluster</Text>
                <Divider borderColor="gray.300" borderBottom="1px" />
                <Grid templateColumns="auto auto">
                  <GridItem borderBottom="1px" borderColor="gray.300" py={1}>
                    <Text as="i">Name</Text>
                  </GridItem>
                  <GridItem borderBottom="1px" borderColor="gray.300">
                    <Text pl="1ch">{alert?.current_inquiry_result?.result?.cluster?.name}</Text>
                  </GridItem>
                  <GridItem borderBottom="1px" borderColor="gray.300">
                    <Text as="i">Category</Text>
                  </GridItem>
                  <GridItem borderBottom="1px" borderColor="gray.300">
                    <Text pl="1ch">{alert?.current_inquiry_result?.result?.cluster?.category}</Text>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Box>

          <Box p={5} bg="white" outline="1px solid black" mb={4}>
            <Text fontWeight={800}>Triggers</Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Category</Th>
                  <Th>% of total exposure</Th>
                  <Th>Message</Th>
                  <Th>Risk</Th>
                  <Th>Min threshold</Th>
                  <Th>Max threshold</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getTriggerDifferences(
                  alert?.prev_inquiry_result?.result.triggers,
                  alert?.current_inquiry_result?.result.triggers
                ).changedTriggers.map((trigger, index) => {
                  return (
                    <Tr key={index}>
                      <Td fontSize="sm" py={1}>
                        {trigger.category}
                      </Td>
                      <Td fontSize="sm" py={1}>
                        {trigger.percentage}
                      </Td>
                      <Td fontSize="sm" py={1}>
                        {trigger.message}
                      </Td>
                      <Td fontSize="sm" py={1}>
                        <Tag bg={`risk.${trigger.ruleTriggered.risk.toUpperCase()}`} color="white">
                          {trigger.ruleTriggered.risk}
                        </Tag>
                      </Td>
                      <Td fontSize="sm" py={1}>
                        {trigger.ruleTriggered.minThreshold}
                      </Td>
                      <Td fontSize="sm" py={1}>
                        {trigger.ruleTriggered.maxThreshold}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </>
      )}{' '}
    </>
  );
};

const AlertDetail = (): JSX.Element => {
  const toast = useToast();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const [isEditingNotes, setIsEditingNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const navigate = useNavigate();
  const { alertID } = useParams();
  if (alertID == null) {
    throw new Error('alertID must be provided');
  }
  const { data, /* isFetching, isLoading, */ refetch } = useGetAlertByIDQuery({
    orgId: activeOrgID,
    alertId: alertID
  });
  const [updateAlert, { isLoading: updateAlertIsLoading }] = useUpdateAlertMutation();

  useEffect(() => {
    if (data?.notes?.length > 0) {
      setNotes(data.notes);
    }
  }, [data?.notes]);

  if (data == null) {
    return <Loading message="Loading Alert details" />;
  }

  const onChangeAlertStatus = async (): Promise<void> => {
    try {
      const newStatus = data?.status === ALERT_STATUS.CLOSED ? ALERT_STATUS.OPEN : ALERT_STATUS.CLOSED;
      if (newStatus === ALERT_STATUS.CLOSED && notes.length === 0) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Please add notes to resolve the alert!',
          isClosable: true
        });
        return;
      }
      if (newStatus === ALERT_STATUS.CLOSED && isEditingNotes) {
        await updateAlert({
          orgId: activeOrgID,
          alertId: alertID,
          notes
        }).unwrap();
        setIsEditingNotes(false);
      }
      await updateAlert({
        orgId: activeOrgID,
        alertId: alertID,
        status: newStatus
      }).unwrap();
      setIsEditingNotes(false);
      analytics.trackAlertStatusChange({
        alertId: alertID,
        opened: newStatus === ALERT_STATUS.OPEN
      });
      if (newStatus === ALERT_STATUS.CLOSED) {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Alert successfully resolved!',
          isClosable: true
        });
        await refetch();
        navigate('/dashboard/alerts');
      } else {
        await refetch();
      }
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Oh no, there was an error!',
        isClosable: true
      });
    }
  };

  return (
    <Container maxW="8xl">
      <Box>
        <AlertHeader alert={data} />

        <AlertBody alert={data} />
        <Box p={5} bg="white" outline="1px solid black" mb={4}>
          <Box as="span">
            <Text as="span" fontWeight={800}>
              Notes
            </Text>
            {!isEditingNotes && (
              <Text
                cursor="pointer"
                onClick={() => {
                  setIsEditingNotes(true);
                }}
                fontSize="sm"
                as="span"
                ml={1}
              >
                (Edit)
              </Text>
            )}
          </Box>
          {isEditingNotes ? (
            <Box mb={3}>
              <Button
                size="xs"
                mt={3}
                mr={2}
                isDisabled={false}
                bg="red"
                colorScheme="green"
                onClick={() => {
                  setIsEditingNotes(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="xs"
                mt={3}
                isLoading={false}
                bg="green.400"
                colorScheme="green"
                onClick={async () => {
                  try {
                    await updateAlert({
                      orgId: activeOrgID,
                      alertId: alertID,
                      notes
                    }).unwrap();
                    setIsEditingNotes(false);
                  } catch (err) {
                    toast({
                      status: 'error',
                      title: 'Error',
                      description: 'Oh no, there was an error!',
                      isClosable: true
                    });
                    Sentry.captureException(err);
                  }
                }}
              >
                Save
              </Button>
            </Box>
          ) : null}

          <Textarea
            placeholder="Add review notes here"
            disabled={!isEditingNotes}
            rows={5}
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </Box>
        <Box mb={4} mr={1}>
          <Flex justifyContent="flex-end">
            <Button
              ml={4}
              mt={1}
              fontSize="sm"
              size="md"
              colorScheme="blue"
              bg="blue.400"
              isDisabled={updateAlertIsLoading}
              onClick={onChangeAlertStatus}
            >
              {data?.status === 'C' ? 'Open Alert' : 'Resolve Alert'}
            </Button>
          </Flex>
        </Box>
      </Box>
    </Container>
  );
};

export default AlertDetail;
