import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useGetProfileByIdQuery, useGetProfileInquiriesQuery } from '@services/canaria.services';
import { selectActiveOrgID } from '../../../features/user-settings/userSlice';
import Loading from '../../../features/loading/loading.component';
import DisplayField from './DisplayField';
import { PROFILE_TYPE, type IProfile } from '../models';

export const ScreenedDataContainer = (): JSX.Element => {
  const { profileId } = useParams();
  const orgId = useSelector(selectActiveOrgID);

  if (orgId == null) {
    throw new Error('activeOrgID is null');
  }

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const { data: profileData, isLoading: isProfileLoading } = useGetProfileByIdQuery({
    orgId,
    profileId
  });

  if (isProfileLoading || profileData == null) {
    return <Loading />;
  }

  return <ScreenedData profile={profileData} />;
};

const UNRESOLVED_STATUS = 'NO_RESOLUTION';

const ScreenedData: React.FC<{ profile: IProfile }> = ({ profile }): JSX.Element => {
  return (
    <VStack spacing={4} align="stretch" m={4} p={10} boxShadow="md" bg="white">
      <Heading as="h1" size="md" textAlign="left" mb={5}>
        Screened Data
      </Heading>
      <Box w="50%">
        <DisplayField label="Profile Name" value={profile.fullLegalName} />
        {profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
          <>
            <DisplayField label="Gender" value={profile.gender?.name} />
            <DisplayField label="Date of Birth" value={profile.dateOfBirth} />
            <DisplayField label="Citizenship" value={profile.citizenship?.englishName} />
            <DisplayField label="Place of Birth" value={profile.placeOfBirth?.englishName} />
            <DisplayField label="Id Number" value={profile.idNumber} />
            <DisplayField label="Id Type" value={profile.idType?.name} />
            <DisplayField label="Id Issuer" value={profile.idIssuer?.englishName} />
          </>
        ) : (
          <>
            <DisplayField label="DBA" value={profile.dbaTradeName} />
            <DisplayField label="Country of Incorporation" value={profile.placeOfEstablishment?.englishName} />
            <DisplayField label="Date of Incorporation" value={profile.dateOfEstablishment} />
            <DisplayField label="Business Address" value={profile.registeredBusinessAddress} />
            <DisplayField label="Physical Address" value={profile.physicalAddress} />
            <DisplayField label="Mailing Address" value={profile.mailingAddress} />
          </>
        )}
      </Box>
    </VStack>
  );
};

const InquiriesList: React.FC<{
  profileId: string;
  title: string;
  inquiries: any[];
}> = ({ profileId, title, inquiries }) => {
  return (
    <VStack spacing={4} align="stretch" m={4} p={10} boxShadow="md" bg="white">
      <Heading as="h1" size="md" textAlign="left" mb={5}>
        {title}
      </Heading>
      <Flex justifyContent="space-between" p={2} borderBottom="1px" borderColor="gray.200">
        <Text flex={1} textAlign="start">
          Name
        </Text>
        <Text flex={1} textAlign="start">
          Watchlist(s)
        </Text>
        <Text flex={1} textAlign="start">
          Category
        </Text>
        <Text flex={1} textAlign="start">
          Score
        </Text>
        <Text flex={1} textAlign="start"></Text>
      </Flex>
      {inquiries.map((hit) => (
        <Flex key={hit.id} justifyContent="space-between" borderBottom="1px" borderColor="gray.200">
          <Text flex={1} textAlign="start">
            {hit.resultName}
          </Text>
          <Text flex={1} textAlign="start">
            {hit.result.listEntry.listSource.lists.map((list) => list.name).join(', ')}
          </Text>
          <Text flex={1} textAlign="start">
            {hit.result.listEntry.listSource.category.name}
          </Text>
          <Text flex={1} textAlign="start">
            {(hit.result.score * 100).toFixed(2)} %
          </Text>
          <Flex flex={1} justifyContent="center">
            {hit.resolution === UNRESOLVED_STATUS ? (
              <Link to={`/dashboard/profiles/${profileId}/screenings/${hit.id}`}>
                <Button bg="black" color="white">
                  Resolve
                </Button>
              </Link>
            ) : (
              <Flex justifyContent="space-between" alignItems="center">
                <Text>{hit.resolution === 'TRUE_MATCH' ? 'True match' : 'False positive'}</Text>
                <Link to={`/dashboard/profiles/${profileId}/screenings/${hit.id}`}>
                  <EditIcon ml={2} cursor="pointer" />
                </Link>
              </Flex>
            )}
          </Flex>
        </Flex>
      ))}
    </VStack>
  );
};

const WatchlistScreening = (): JSX.Element => {
  const { profileId } = useParams();
  const orgId = useSelector(selectActiveOrgID);

  if (orgId == null) {
    throw new Error('activeOrgID is null');
  }

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const { data: inquiriesData, isLoading: isInquieriesLoading } = useGetProfileInquiriesQuery({
    orgId,
    profileId
  });

  const inquiries = inquiriesData?.results ?? [];

  if (isInquieriesLoading) {
    return <Loading message="Loading profile inquiries..." />;
  }

  return (
    <>
      <Heading as="h1" size="lg" textAlign="center" mb={5}>
        Watchlist Screening
      </Heading>
      <Flex justifyContent="end" mr={4}>
        <Link to={`/dashboard/profiles/${profileId}/`}>
          <Button bg="black" color="white">
            Return
          </Button>
        </Link>
      </Flex>
      <ScreenedDataContainer />

      <InquiriesList
        profileId={profileId}
        title="New Hits Needing Attention"
        inquiries={inquiries?.filter((inquiry) => inquiry.resolution === UNRESOLVED_STATUS)}
      />

      <InquiriesList
        profileId={profileId}
        title="Previously Resolved Hits"
        inquiries={inquiries?.filter((inquiry) => inquiry.resolution !== UNRESOLVED_STATUS)}
      />
    </>
  );
};

export default WatchlistScreening;
