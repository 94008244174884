import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  MenuDivider,
  Divider,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import md5 from 'md5';
import isEmpty from 'lodash/isEmpty';

import { ChevronDownIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { MdGroup, MdNotificationsNone } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import { SlWallet } from 'react-icons/sl';

import Footer from './Footer';
import { selectActiveOrgID, selectUser, userClearOrgStateAction } from '../../features/user-settings/userSlice';
import { useGetMeQuery, useGetOrganizationQuery } from '@services/canaria.services';

interface SidePanelProps {
  children?: React.ReactNode;
}

const TopNavBar = ({ userName, email }): JSX.Element => {
  const gravatarUrl = 'https://www.gravatar.com/avatar/' + md5(email);
  const dispatch = useDispatch();

  return (
    <Flex
      as="header"
      align="center"
      justify="flex-end"
      paddingX="4"
      paddingY="5"
      bg="white"
      boxShadow="md"
      zIndex="banner"
    >
      <Menu>
        <MenuButton as={Button} variant="unstyled" _focus={{ boxShadow: 'none' }} _hover={{ bg: 'transparent' }}>
          <Flex align="center">
            <Avatar size="md" name={userName} src={gravatarUrl} mr={2} />
            <Text fontWeight="normal">Welcome back, {userName}!</Text>
            <ChevronDownIcon ml={2} />
          </Flex>
        </MenuButton>
        <MenuList zIndex={2}>
          <MenuItem
            onClick={() => {
              dispatch(userClearOrgStateAction());
            }}
          >
            Switch Organization
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.replace('https://backend.canariaconsulting.com/acct/change-password/');
            }}
          >
            Change Password
          </MenuItem>
          <MenuDivider />
          <Link to="/logout">
            <MenuItem>Logout</MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </Flex>
  );
};

const SidePanelMenuItem = ({ icon, label, route }): JSX.Element => {
  return (
    <Link to={route} style={{ textDecoration: 'none' }}>
      <Flex minH="40px" bg="transparent" _hover={{ bg: 'gray.200' }} borderRadius="md" p={2}>
        <Icon as={icon} fontSize="20px" mr={2} />
        <Text fontSize="md">{label}</Text>
      </Flex>
    </Link>
  );
};

const SidePanel: React.FC<SidePanelProps> = ({ children }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const { isLoading: meIsLoading } = useGetMeQuery(null);
  const { isLoading: orgIsLoading } = useGetOrganizationQuery(activeOrgID as string);
  if (!meIsLoading && !orgIsLoading) {
    // eslint-disable-next-line no-console
    console.log('Loaded Me and Org');
  }
  const [collapsed, setCollapsed] = useState(false);
  const userState = useSelector(selectUser);
  const { onToggle } = useDisclosure();
  const bgValue = useColorModeValue('gray.100', 'gray.900');

  const handleToggle = (): void => {
    setCollapsed(!collapsed);
    onToggle();
  };

  return (
    <Flex>
      <Box
        width={collapsed ? '80px' : '220px'}
        minWidth={collapsed ? '80px' : '220px'}
        bg="white"
        p={4}
        transition="width 0.2s ease-in-out"
      >
        {collapsed && (
          <IconButton aria-label="Toggle Menu" icon={<HamburgerIcon />} fontSize="20px" onClick={handleToggle} mb={4} />
        )}
        {!collapsed && (
          <Flex direction="row" mb={4}>
            {isEmpty(userState.orgImageUrl) ? (
              <Skeleton height="50px" width="50px" mr={2} />
            ) : (
              <Image src={userState.orgImageUrl} alt={userState.orgName} boxSize="50px" mr={2} />
            )}

            <Text>{userState.orgName}</Text>
            <Spacer />
            <IconButton aria-label="Toggle Menu" icon={<CloseIcon />} onClick={handleToggle} mb={4} ml={1} size="sm" />
          </Flex>
        )}

        <VStack align="stretch" spacing={4} mt={8}>
          {collapsed ? (
            <VStack>
              {/* <Link to="/dashboard">
                <IconButton aria-label="Dashboard" fontSize="20px" icon={<MdDashboard />} />
              </Link> */}
              <Link to="/dashboard/profiles">
                <IconButton aria-label="Profiles" fontSize="20px" icon={<MdGroup />} />
              </Link>
              <Link to="/dashboard/wallets">
                <IconButton aria-label="Wallets" fontSize="20px" icon={<SlWallet />} />
              </Link>
              <Link to="/dashboard/screening">
                <IconButton aria-label="Screening" fontSize="20px" icon={<FiSearch />} />
              </Link>
              <Link to="/dashboard/alerts">
                <IconButton aria-label="Alert" fontSize="20px" icon={<MdNotificationsNone />} />
              </Link>
            </VStack>
          ) : (
            <VStack spacing={2} align="left">
              {/* <SidePanelMenuItem icon={MdDashboard} label="Dashboard" route="/dashboard" /> */}
              <SidePanelMenuItem icon={MdGroup} label="Profiles" route="/dashboard/profiles" />
              <SidePanelMenuItem icon={SlWallet} label="Wallets" route="/dashboard/wallets" />
              <SidePanelMenuItem icon={FiSearch} label="Screening" route="/dashboard/screening" />
              <SidePanelMenuItem icon={MdNotificationsNone} label="Alerts" route="/dashboard/alerts" />
            </VStack>
          )}
        </VStack>
      </Box>

      <Divider orientation="vertical" />

      <Box flex="1" bg={bgValue} minHeight="100vh" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <TopNavBar userName={userState.firstName} email={userState.email} />
          <Box mt={5}>{children}</Box>
        </Box>
        <Footer />
      </Box>
    </Flex>
  );
};

export default SidePanel;
