import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../env';

interface SurveyResponse {
  name: string;
  public_link: string;
  survey_definition: Record<string, unknown>;
  survey_style: Record<string, unknown>;
}

export const canariaFormApi = createApi({
  reducerPath: 'canariaFormApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BACKEND_URL}/api/` }),
  tagTypes: ['Form'],
  endpoints: (builder) => ({
    getFormById: builder.query<SurveyResponse, string>({
      query: (uniqueId) => {
        // the backend expects a trailing slash always
        let url = `forms/${uniqueId}`;
        if (!url.endsWith('/')) {
          url += '/';
        }
        return url;
      },
      transformResponse: (response: SurveyResponse) => response,
      providesTags: (_result, _error, uniqueId) => [{ type: 'Form', id: uniqueId }]
    })
  })
});

export const { useGetFormByIdQuery } = canariaFormApi;
