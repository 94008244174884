import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

const useQueryParams = (): {
  queryParams: Record<string, string>;
  setQueryParams: (params: Record<string, string | number | undefined>) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  }, [searchParams]);

  const setQueryParams = (params: Record<string, string | number | undefined>): void => {
    const newSearchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        if (typeof value === 'number') {
          newSearchParams.set(key, value.toString());
        } else {
          newSearchParams.set(key, value);
        }
      }
    }

    setSearchParams(newSearchParams);
  };

  return { queryParams, setQueryParams };
};

export default useQueryParams;
