import { Box, Text, Flex, Image, useBreakpointValue, VStack } from '@chakra-ui/react';
const LeftPanel = (): JSX.Element => {
  const panelSize = useBreakpointValue({ base: '100%', md: '50%' });

  return (
    <Box
      bg="blackAlpha.800"
      p={10}
      color="white"
      w={panelSize}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        backgroundImage: `url("https://img.freepik.com/premium-photo/vertical-black-white-dark-lines-texture-background-hd_199726-11279.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <VStack spacing={4} align="start" marginTop="-10%" marginLeft={16}>
        <Box>
          <Flex alignItems="center">
            <Image src="/resources/apple-touch-icon.png" boxSize="50px" marginRight={3} />
            <Text fontSize="6xl">Canaria</Text>
          </Flex>
          <Text fontSize="xl" color="green.500" fontWeight="bold">
            Web3 Due Diligence
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default LeftPanel;
