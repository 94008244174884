import isEmail from 'validator/lib/isEmail';

export const toCamelCase = (str: string): string => {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
};

export const convertObjectKeysToCamelCase = <T extends Record<string, any>>(obj: Record<string, any>): T => {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertObjectKeysToCamelCase) as unknown as T;
  }

  const newObj: Record<string, any> = {};
  for (const [key, value] of Object.entries(obj)) {
    newObj[toCamelCase(key)] = convertObjectKeysToCamelCase(value);
  }
  return newObj as T;
};

export const validateEmail = (email: string | null): boolean => {
  if (email == null) {
    return false;
  }
  return isEmail(email);
};
