import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useGetProfileAuditLogsQuery } from '@services/canaria.services';
import { selectActiveOrgID } from '../../../features/user-settings/userSlice';
import dayjs from 'dayjs';

interface ProfileAuditProps {
  profileId: string;
}

const ProfileAudit: React.FC<ProfileAuditProps> = ({ profileId }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('No active org ID found');
  }

  const { data, isLoading, refetch } = useGetProfileAuditLogsQuery({
    orgId: activeOrgID,
    profileId,
    query: {
      page: 1,
      page_size: 100
    }
  });

  const auditLogs = data?.results ?? [];

  const formatChanges = (changes: Record<string, [string, string]>): JSX.Element => {
    return (
      <VStack align="start" spacing={1} mt={2} pl={4} borderLeft="2px solid #e2e8f0">
        {Object.entries(changes).map(([field, [oldValue, newValue]]) => (
          <Text key={field}>
            <strong>{field.replace(/_/g, ' ')}:</strong> {`"${oldValue ?? 'N/A'}"`} → {`"${newValue ?? 'N/A'}"`}
          </Text>
        ))}
      </VStack>
    );
  };

  return (
    <VStack spacing={4} align="stretch" w="100%">
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Heading as="h1" size="md" textAlign="left" mb={5}>
          Audit Logs
        </Heading>
        <Button onClick={refetch} isLoading={isLoading} colorScheme="teal" borderRadius="full">
          Refresh
        </Button>
      </Flex>
      {auditLogs.length === 0 && <Text>No audit logs found.</Text>}
      {auditLogs.map((log) => (
        <Box
          key={log.id}
          px={3}
          py={2}
          shadow="md"
          borderWidth="1px"
          w="100%"
          position="relative"
          backgroundColor="white"
        >
          <Text fontWeight="bold">{log.objectRepr}</Text>
          <Flex justifyContent="space-between" alignItems="center" mt={2}>
            <Text fontSize="xs" color="gray.500">
              {dayjs(log.timestamp).format('YYYY-MM-DD HH:mm:ss')}
            </Text>
            <Text fontSize="xs" color="gray.500">
              {log.updatedBy ?? 'Unknown User'}
            </Text>
          </Flex>
          {formatChanges(log.changes)}
        </Box>
      ))}
    </VStack>
  );
};

export default ProfileAudit;
