import { useState } from 'react';
import { Text } from '@chakra-ui/react';

import { useGetProfileByIdQuery } from '@services/canaria.services';
import Loading from '../../../features/loading/loading.component';
import { RelatedPartyManager } from '../ProfileWizard/common/addRelatedParty';

interface IRelatedPartiesSectionProps {
  orgId: string;
  profileId: string;
}
const RelatedPartiesSection: React.FC<IRelatedPartiesSectionProps> = ({ orgId, profileId }): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const { data: profile, isLoading } = useGetProfileByIdQuery({
    orgId,
    profileId
  });

  if (isLoading || profile == null) {
    return <Loading message="Loading related parties..." />;
  }

  return (
    <RelatedPartyManager orgId={orgId} profile={profile} showForm={showForm} setShowForm={setShowForm}>
      <Text fontSize="sm" align="center">
        No related parties added yet.{' '}
        <Text
          as="span"
          textDecoration="underline"
          cursor="pointer"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Add a related party
        </Text>
      </Text>
    </RelatedPartyManager>
  );
};

export default RelatedPartiesSection;
