import { useState, createContext, useContext } from 'react';

interface ProfileWizardContextProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  maxSteps: number;
  setMaxSteps: (step: number) => void;
  stepTitles: string[];
  setStepTitles: (titles: string[]) => void;
}

const ProfileWizardContext = createContext<ProfileWizardContextProps>({
  currentStep: 1,
  setCurrentStep: () => {},
  maxSteps: 6,
  setMaxSteps: () => {},
  stepTitles: [],
  setStepTitles: () => {}
});

export const useProfileWizard = (): ProfileWizardContextProps => {
  return useContext(ProfileWizardContext);
};

export const ProfileWizardProvider = ({ children }): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(1);
  const [maxSteps, setMaxSteps] = useState(2);
  const [stepTitles, setStepTitles] = useState<string[]>([]);

  const value = {
    currentStep,
    setCurrentStep,
    maxSteps,
    setMaxSteps,
    stepTitles,
    setStepTitles
  };

  return <ProfileWizardContext.Provider value={value}>{children}</ProfileWizardContext.Provider>;
};
