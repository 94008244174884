import { Flex, Icon, Link, Text } from '@chakra-ui/react';
import { FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear();
  return (
    <Flex as="footer" justify="space-between" align="center" padding="1.5rem">
      <Text fontSize="sm" color="gray.500">
        © 2023-{currentYear} Canaria Consulting. All rights reserved
      </Text>
      <Flex>
        <Link href="https://www.linkedin.com/company/canaria-consulting/" isExternal>
          <Icon as={FaLinkedinIn} boxSize="24px" color="gray.500" marginRight="1rem" cursor="pointer" />
        </Link>
        <Link href="https://www.instagram.com/canariaco/" isExternal>
          <Icon as={FaInstagram} boxSize="24px" color="gray.500" marginRight="1rem" cursor="pointer" />
        </Link>
      </Flex>
    </Flex>
  );
};

export default Footer;
