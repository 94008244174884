import { useDispatch } from 'react-redux';
import { useState } from 'react';
import AuthAPI from '../../../features/auth/api';
import { type UseToastOptions } from '@chakra-ui/react';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Link,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import LeftPanel from './leftPanel';
import Footer from '../Footer';
import { GoogleLogin } from '@react-oauth/google';
import Loading from '../../../features/loading/loading.component';

import { useLoginMutation, useLoginWithGoogleMutation } from '@services/canaria.services';

import { useNavigate } from 'react-router-dom';

const toastInvalidCredentials: UseToastOptions = {
  status: 'error',
  title: 'Invalid Credentials',
  description: 'The account specified does not exist or the password is incorrect.',
  isClosable: true
};

const toastDefaultError: UseToastOptions = {
  status: 'error',
  title: 'Error',
  description: 'Oh no, there was an error!',
  isClosable: true
};

const RightPanel = (): JSX.Element => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [loginWithGoogle, { isLoading: isLoadingGoogle }] = useLoginWithGoogleMutation();

  const [formState, setFormState] = useState({
    username: '',
    password: ''
  });

  const handleInputChange = ({ target: { name, value } }): void => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLoginClick = async (): Promise<void> => {
    try {
      const authApi = new AuthAPI(dispatch);
      authApi.logout();
      const tokens = await login(formState).unwrap();
      authApi.login(tokens.refresh, tokens.access);
      navigate('/dashboard');
    } catch (err: any) {
      if (err.status === 401) {
        toast(toastInvalidCredentials);
      } else {
        toast(toastDefaultError);
      }
    }
  };
  return (
    <Box bg="white" w="50%" px={52} py={10} display="flex" alignItems="center" justifyContent="center">
      <VStack spacing={1} align="flex-start" w="100%">
        <Text fontSize="2xl">Sign in to your account</Text>
        <FormControl>
          <Stack spacing={4}>
            <FormLabel fontSize="sm">Email Address</FormLabel>
            <Input type="email" name="username" onChange={handleInputChange} />
            <FormLabel fontSize="sm">Password</FormLabel>
            <Input type="password" name="password" onChange={handleInputChange} />
            <Flex justifyContent="space-between" alignItems="center">
              <Checkbox fontSize="sm">
                <Text fontSize="sm">Remember me</Text>
              </Checkbox>
              <Link
                href="https://backend.canariaconsulting.com/acct/password-reset/"
                fontSize="sm"
                color="blue.500"
                isExternal
              >
                Forgot your password?
              </Link>
            </Flex>
            <Button fontSize="sm" colorScheme="green" onClick={handleLoginClick} isLoading={isLoading}>
              Sign in
            </Button>
            <Center>
              <Link fontSize="sm" color="blue.500" href="mailto:carolyn@canariaconsulting.com">
                Request an Account
              </Link>
            </Center>
            {isLoadingGoogle ? (
              <Loading message="Signing you in... Please wait." />
            ) : (
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  const authApi = new AuthAPI(dispatch);
                  authApi.logout();
                  try {
                    const tokens = await loginWithGoogle(credentialResponse).unwrap();
                    authApi.login(tokens.refresh, tokens.access);

                    navigate('/dashboard/profiles');
                  } catch (err: any) {
                    if (err.status === 401) {
                      toast({
                        status: 'error',
                        title: 'Invalid Credentials',
                        description:
                          'The Google account specified is not authorized for our system. Please contact info@canariaconsulting.com for assistance.',
                        isClosable: false
                      });
                    } else {
                      toast(toastDefaultError);
                    }
                  }
                }}
                onError={() => {
                  toast({
                    status: 'error',
                    title: 'Invalid Credentials',
                    description: 'There was an error logging in with Google. Please try again.',
                    isClosable: true
                  });
                }}
                useOneTap
              />
            )}
          </Stack>
        </FormControl>
      </VStack>
    </Box>
  );
};

const SignInForm = (): JSX.Element => {
  return (
    <Flex flexDirection="column" minH="100vh">
      <Flex flex="1">
        <LeftPanel />
        <RightPanel />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default SignInForm;
