import dayjs from 'dayjs';
import { useMemo, useState, type JSX } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { ThemeProvider, createTheme, Box, Button } from '@mui/material';
import { Center, Container, Heading, useDisclosure } from '@chakra-ui/react';
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { useGetWalletsQuery } from '@services/canaria.services';
import { selectActiveOrgID } from '../../user-settings/userSlice';
import { RISK_BG_COLORS } from '../../../utils/consts';
import WalletModal from '../../../pages/v2/ProfileWizard/common/addWalletModal';

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['id', 'risk_level', 'name', 'address', 'created_at', 'recent_screening', 'cadence', 'notes']
};

const minifyAddress = (address: string): string => {
  if (address.length > 20) {
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
  }
  return address;
};

export const WalletsTable: React.FC<{ profileId?: string }> = ({ profileId }): JSX.Element => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const defaultMaterialTheme = createTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<any[]>([]);
  const { data, isFetching, isLoading, error } = useGetWalletsQuery({
    orgId: activeOrgID,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter,
      ...columnFilters.reduce((acc: any, curr: any) => {
        if (curr?.value != null) {
          acc[curr.id.replaceAll('.', '__')] = curr.value;
        }
        return acc;
      }, {}),
      profiles: profileId
    }
  });
  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        size: 4,
        Cell: ({ cell }: any) => (
          <Link
            to={{ pathname: `/dashboard/wallets/${cell.getValue()}` }}
            state={{
              profileId
            }}
          >
            {cell.getValue()}
          </Link>
        )
      },
      {
        header: 'Risk Level',
        accessorFn: (row) => row?.last_inquiry?.result?.risk,
        size: 5,
        Cell: ({ cell }: any) => {
          const risk = cell.getValue() ?? '';
          return (
            <Box
              sx={(theme) => ({
                backgroundColor: RISK_BG_COLORS[risk.toUpperCase()] ?? 'white',
                borderRadius: '0.25rem',
                color: '#fff',
                minWidth: '6ch',
                maxWidth: '8ch',
                p: '0.2rem'
              })}
            >
              <center>{risk}</center>
            </Box>
          );
        },
        filterVariant: 'select' as 'select', // FIX THIS WORKAROUND
        filterSelectOptions: [
          { text: 'Low', value: 1 },
          { text: 'Medium', value: 2 },
          { text: 'High', value: 3 },
          { text: 'Severe', value: 4 }
        ]
      },
      {
        header: 'Name',
        accessorKey: 'name',
        size: 6,
        Cell: ({ cell }: any) => (
          <Link to={{ pathname: `/dashboard/wallets/${cell.row.original.id}` }}>{cell.getValue()}</Link>
        )
      },
      {
        header: 'Address',
        accessorKey: 'address',
        size: 6,
        Cell: ({ cell }: any) => (
          <Link to={{ pathname: `/dashboard/wallets/${cell.row.original.id}` }}>{minifyAddress(cell.getValue())}</Link>
        )
      },
      {
        header: 'Initial Screening',
        accessorKey: 'created_at',
        Cell: ({ cell: { getValue } }) => {
          const value = getValue();
          if (value == null) return null;
          return dayjs(value).format('YYYY-MM-DD hh:mma');
        },
        size: 6,
        enableColumnFilter: false
      },
      {
        header: 'Recent Screening',
        accessorFn: (row) => row?.last_inquiry?.created_at,
        Cell: ({ cell: { getValue } }) => {
          const value = getValue();
          if (value == null) return null;
          return dayjs(value).format('YYYY-MM-DD hh:mma');
        },
        enableColumnFilter: false
      },
      {
        header: 'Cadence',
        accessorKey: 'schedule.frequency',
        accessorFn: (row) => (row?.schedule != null ? row.schedule.frequency : null),
        enableColumnFilter: false,
        size: 1
      }
    ],
    [profileId]
  );

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows): void => {
    csvExporter.generateCsv(
      rows.map((row) => {
        const r = row.original;
        return {
          id: r.id,
          risk_level: r.last_inquiry.result.risk,
          name: r.name,
          address: r.address,
          created_at: r.created_at,
          recent_screening: r.last_inquiry.created_at,
          cadence: r.schedule?.frequency ?? null,
          notes: r.notes
        };
      })
    );
  };

  let result = <></>;

  if (data != null) {
    result = (
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialReactTable
          columns={columns}
          data={data.results}
          enableRowSelection
          initialState={{
            density: 'compact'
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                p: '0.5rem',
                flexWrap: 'wrap'
              }}
            >
              <Button
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                // only export selected rows
                onClick={() => {
                  handleExportRows(table.getSelectedRowModel().rows);
                }}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
              <Button onClick={onOpen}>Register New Wallet</Button>
            </Box>
          )}
          muiPaginationProps={{
            rowsPerPageOptions: [25, 50, 100, 500],
            showFirstButton: false,
            showLastButton: false
          }}
          manualFiltering
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          manualPagination
          onPaginationChange={setPagination}
          manualSorting
          onSortingChange={setSorting}
          state={{
            globalFilter,
            pagination,
            sorting,
            isLoading: isFetching || isLoading
          }}
          rowCount={data.count}
          enableColumnFilters={true}
        />
      </ThemeProvider>
    );
  } else if (error != null) result = <p>Error encountered loading data</p>;
  return (
    <>
      <WalletModal isOpen={isOpen} onClose={onClose} orgId={activeOrgID} profileId={profileId} />
      {result}
    </>
  );
};

const WalletsPanel = (): JSX.Element => {
  return (
    <Container maxW="full">
      <Center>
        <Heading pb="20px" size="lg">
          Wallets
        </Heading>
      </Center>{' '}
      <WalletsTable />{' '}
    </Container>
  );
};

export default WalletsPanel;
