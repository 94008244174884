import type { ReactNode } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../auth/authSlice';
import { selectActiveOrgID } from '../user-settings/userSlice';

interface ProtectedRouteProps {
  children?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }: { children?: ReactNode }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const activeOrgID = useSelector(selectActiveOrgID);
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (activeOrgID === undefined) {
    return <Navigate to="/organizations" state={{ from: location }} replace />;
  }

  return children ?? <Outlet />;
};

export default ProtectedRoute;
