import ErrorDisplay from './errorDisplay';

interface ErrorComponentProps {
  message?: string;
}

const ErrorNotFound404 = ({ message }: ErrorComponentProps): JSX.Element => (
  <ErrorDisplay statusCode={404} defaultMessage="The page you are looking for does not exist." message={message} />
);

const ErrorServer500 = ({ message }: ErrorComponentProps): JSX.Element => (
  <ErrorDisplay statusCode={500} defaultMessage="Internal server error. Please try again later." message={message} />
);

export { ErrorNotFound404, ErrorServer500 };
