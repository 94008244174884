import { useState, useMemo, type JSX } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, ThemeProvider, createTheme } from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Center, Container, Heading } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RISK_BG_COLORS } from '../../../utils/consts';
import { selectActiveOrgID } from '../../user-settings/userSlice';
import dayjs from 'dayjs';
import { useGetAlertsQuery } from '@services/canaria.services';

const AlertsPanel = (): JSX.Element => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const defaultMaterialTheme = createTheme();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const { data, isFetching, isLoading /* error */ } = useGetAlertsQuery({
    orgId: activeOrgID,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter
    }
  });
  const columns = useMemo<Array<MRT_ColumnDef<any>>>(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        size: 4,
        Cell: ({ cell: { getValue } }: any) => <Link to={`/dashboard/alerts/${getValue()}`}>{getValue()}</Link>
      },
      {
        header: 'Status',
        accessorKey: 'status',
        Cell: ({ cell: { getValue } }) => (getValue() === 'O' ? 'Open' : 'Closed'),
        size: 4
      },
      {
        header: 'Name',
        accessorFn(originalRow) {
          if (originalRow.resourcetype === 'WatchlistAlert') {
            return originalRow.name;
          } else if (originalRow.resourcetype === 'WalletAlert') {
            return originalRow.wallet?.name;
          }
          return '-';
        },
        Cell: ({ cell: { getValue } }: any) => <>{getValue()}</>,
        size: 8
      },
      {
        header: 'Source',
        id: 'resourcetype',
        accessorFn(originalRow) {
          if (originalRow.resourcetype === 'WatchlistAlert') {
            return 'Watchlist Screening';
          } else if (originalRow.resourcetype === 'WalletAlert') {
            return 'Wallet Screening';
          } else if (originalRow.resourcetype === 'NegativeNewsAlert') {
            return 'Negative News';
          }
          return '-';
        },
        Cell: ({ cell: { getValue } }: any) => {
          return <>{getValue()}</>;
        },
        size: 8
      },
      {
        header: 'Entity',
        accessorFn(originalRow) {
          if (originalRow.resourcetype === 'WatchlistAlert') {
            return originalRow.profile.name;
          } else if (originalRow.resourcetype === 'WalletAlert') {
            return originalRow.wallet?.address;
          }
          return '-';
        },
        enableClickToCopy: true,
        size: 6
      },
      {
        header: 'Risk Level',
        accessorKey: 'current_inquiry_result.result.risk',
        size: 5,
        Cell: ({ cell }: any) => {
          const risk = cell.getValue();
          return (
            <Box
              sx={(theme) => ({
                backgroundColor: RISK_BG_COLORS[risk?.toUpperCase()] ?? 'white',
                borderRadius: '0.25rem',
                color: '#fff',
                minWidth: '6ch',
                maxWidth: '8ch',
                p: '0.4rem'
              })}
            >
              <center>{risk}</center>
            </Box>
          );
        }
      },

      {
        header: 'Created at',
        accessorKey: 'created_at',
        Cell: ({ cell: { getValue } }: any) => dayjs(getValue()).format('YYYY-MM-DD hh:mm:ss A'),
        size: 6
      },
      {
        id: 'review',
        header: 'Review',
        accessorKey: 'id',
        Cell: ({ cell: { getValue } }: any) => (
          <Link to={`/dashboard/alerts/${getValue()}`}>
            <Button variant="contained" color="primary" size="small">
              Review Alert
            </Button>
          </Link>
        ),
        size: 6
      }
    ],
    []
  );
  return (
    <Container maxW="8xl">
      <Center>
        <Heading pb="20px" size="lg" color="gray.700">
          Alerts
        </Heading>
      </Center>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialReactTable
          columns={columns}
          data={data?.results ?? []}
          enableRowSelection
          initialState={{
            density: 'compact'
          }}
          muiPaginationProps={{
            rowsPerPageOptions: [25, 50, 100, 500],
            showFirstButton: false,
            showLastButton: false
          }}
          manualFiltering
          onGlobalFilterChange={setGlobalFilter}
          manualPagination
          onPaginationChange={setPagination}
          manualSorting
          onSortingChange={setSorting}
          state={{
            globalFilter,
            pagination,
            sorting,
            isLoading: isFetching || isLoading
          }}
          rowCount={data?.count}
          enableColumnFilters={false}
        />
      </ThemeProvider>
    </Container>
  );
};

export default AlertsPanel;
