import { createSlice, isAnyOf, createAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { loadLocalStorageState, saveLocalStorageState } from '../local-storage/local-storage';
import { authLogoutAction } from '../auth/authSlice';
import { canariaApi } from '@services/canaria.services';
import analytics from '../analytics/analytics';
import { USE_SENTRY } from '../../env';

interface IInitialState {
  orgId: string | undefined;
  orgName: string;
  orgImageUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
}

const key = 'user';
const localStorage = loadLocalStorageState<IInitialState>(key);

const userClearOrgStateAction = createAction('USER_CLEAR_ORG_STATE');

const initialState: IInitialState = {
  orgId: localStorage?.orgId,
  orgName: '',
  orgImageUrl: '',
  firstName: '',
  lastName: '',
  email: '',
  username: ''
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setActiveOrgID: (state, action) => {
      state.orgId = action.payload;

      saveLocalStorageState(key, {
        orgId: action.payload
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(authLogoutAction), (state, action) => {
        state.orgId = undefined;
        state.orgName = '';
        state.orgImageUrl = '';
        state.firstName = '';
        state.lastName = '';
        state.email = '';
        state.username = '';

        if (USE_SENTRY) {
          Sentry.setUser(null);
        }
      })
      .addMatcher(isAnyOf(userClearOrgStateAction), (state, action) => {
        state.orgId = undefined;
        state.orgName = '';
        state.orgImageUrl = '';

        if (USE_SENTRY) {
          Sentry.setContext('organization', null);
        }
      })
      .addMatcher(canariaApi.endpoints.getMe.matchFulfilled, (state, action) => {
        state.firstName = action.payload.first_name;
        state.lastName = action.payload.last_name;
        state.email = action.payload.email;
        state.username = action.payload.username;

        if (USE_SENTRY) {
          Sentry.setUser({ email: action.payload.email });
        }

        analytics.trackIdentify(
          action.payload.email,
          action.payload.first_name,
          action.payload.last_name,
          action.payload.date_joined
        );
      })
      .addMatcher(canariaApi.endpoints.getOrganization.matchFulfilled, (state, action) => {
        state.orgName = action.payload.name;
        state.orgImageUrl = action.payload.image.standard;

        if (USE_SENTRY) {
          Sentry.setContext('organization', {
            name: action.payload.name,
            org_id: state.orgId
          });
          analytics.trackOrganization(state.orgId, action.payload.name);
        }
      });
  }
});

export { userClearOrgStateAction };
export const { setActiveOrgID } = slice.actions;

export const selectActiveOrgID = (state: { user: IInitialState }): string | undefined => state.user.orgId;
export const selectUser = (state): IInitialState => state.user;

export default slice.reducer;
