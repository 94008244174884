import { useEffect, type JSX } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Heading, Card, CardHeader, Image, CardFooter, Button, Text, useToast, Flex } from '@chakra-ui/react';
import Header from '../features/header/header.component';
import Footer from '../features/footer/footer.component';
import { useGetOrganizationsQuery } from '@services/canaria.services';
import Loading from '../features/loading/loading.component';
import { setActiveOrgID } from '../features/user-settings/userSlice';

const OrganizationsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetOrganizationsQuery(null);

  useEffect(() => {
    if (!isLoading && data != null) {
      if (data.count === 1) {
        // Automatically pick the only organization available
        dispatch(setActiveOrgID(data.results[0].id));
        navigate('/dashboard');
      }
    }
  }, [isLoading, data, dispatch, navigate]);

  return (
    <>
      <Header></Header>
      <Flex direction="column" mb={4}>
        <Text align="center" pt={10} pb={10} fontSize="2xl">
          Select Organization
        </Text>
        {error != null ? (
          <p>Error loading organizations</p>
        ) : isLoading ? (
          <Loading message="Loading your organizations..." />
        ) : data != null ? (
          <Flex wrap="wrap" justify="center" width="100%" px={[4, 6, 10]} pb="100px" gap={5}>
            {data?.results.map((d, idx) => (
              <Card key={idx} maxW="400px" minW="400px" flex="1 1 300px" m={3}>
                <CardHeader>
                  <Heading size="md" textAlign="center">
                    {d.name}
                  </Heading>
                </CardHeader>
                <Image objectFit="cover" src={d.image.standard} alt={d.name} />
                <CardFooter justify="center">
                  <Button
                    colorScheme="green"
                    bg="green.400"
                    _hover={{ bg: 'green.500' }}
                    onClick={async () => {
                      try {
                        dispatch(setActiveOrgID(data.results[idx].id));
                        navigate('/dashboard/profiles');
                      } catch (err: any) {
                        const status = err.status ?? 500;
                        const errorMessage =
                          status === 401
                            ? 'The account specified does not exist or the password is incorrect.'
                            : 'Oh no, there was an error!';
                        toast({
                          status: 'error',
                          title: status === 401 ? 'Invalid Credentials' : 'Error',
                          description: errorMessage,
                          isClosable: true
                        });
                      }
                    }}
                  >
                    Set as Active
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </Flex>
        ) : (
          <p>You don&apos;t have access to any organizations</p>
        )}
      </Flex>
      <Footer></Footer>
    </>
  );
};

export default OrganizationsPage;
