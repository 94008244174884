import { combineReducers } from 'redux';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/user-settings/userSlice';
import { canariaApi } from '@services/canaria.services';
import { canariaFormApi } from '@services/canaria.form.services';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  [canariaApi.reducerPath]: canariaApi.reducer,
  [canariaFormApi.reducerPath]: canariaFormApi.reducer
});
